import { createApp } from "vue";
import { MotionPlugin } from "@vueuse/motion";
import { createI18n } from "vue-i18n";
import App from "./App.vue";
import axios from "axios";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import DE from "./locale/de.json";
import EN from "./locale/en.json";
import "./assets/tailwind.css";
import { createRouter, createWebHistory } from "vue-router";
// import HomePageVue from "./pages/HomePage.vue";
// import ProductsPageVue from "./pages/ProductsPage.vue";
// import ServicesPageVue from "./pages/ServicesPage.vue";
// import CompanyPageVue from "./pages/CompanyPage.vue";
import store from "./store";

axios.defaults.baseURL =
  process.env.NODE_ENV === "production"
    ? "https://infranovagroup.com"
    : process.env.VUE_APP_API_BASE;
console.log(process.env.VUE_APP_API_BASE);

axios.defaults.timeout = 5000;



// toast options
const toastOptions = {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
  position: "top-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false,
};

// router
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      redirect: "/home",
    },
    { path: "/home", name: "home", component: () => import("./pages/HomePage.vue") },
    { path: "/products", name: "products", component: () => import("./pages/ProductsPage.vue") },
    { path: "/services", name: "services", component: () => import("./pages/ServicesPage.vue") },
    { path: "/company", name: "company", component: () => import("./pages/CompanyPage.vue") },
    { path: "/subscription", name: "subscription", component: () => import("./pages/SubscribePage.vue") },

    { path: "/:notFound(.*)", redirect: "/home" },
  ],
  scrollBehavior(to, _, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (to.hash) {
          resolve({ el: to.hash });
        } else if (savedPosition) {
          resolve(savedPosition);
        } else {
          resolve({ left: 0, top: 0 });
        }
      }, 200); // adjust time according to your transition time
    });
  },
});

const i18n = createI18n({
  locale: store.state.localeLanguage,
  messages: {
    EN: EN,
    DE: DE,
  },
});
const app = createApp(App);
app.use(i18n);
app.use(store);
app.use(router, axios);
app.use(Toast, toastOptions);
app.use(MotionPlugin);
app.mount("#app");
